<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neue Playlist">
      <video-category-form
        :category="category"
        submit-label="Neue Playlist anlegen"
        :form-submitted="saveCategory"
        mode="new"
      ></video-category-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import VideoCategoryForm from "@/components/forms/VideoCategoryForm"
import VideoCategoriesRepository from "@/repositories/video_categories_repository.js"

export default {
  metaInfo: {
    title: "Neue Playlist"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    VideoCategoryForm
  },
  data() {
    return {
      category: {}
    }
  },
  async created() {},
  methods: {
    async saveCategory(category) {
      await VideoCategoriesRepository.create(category)
      this.$router.push({ name: "VideoCategoriesPage" })
    }
  }
}
</script>
