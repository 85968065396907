<template>
  <base-form
    v-slot="slotProps"
    :model="category"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    :additional-validators="[
      {
        validator: checkThumbnail,
        onError: missingThumbnailWarning
      }
    ]"
    model-name="Playlist"
  >
    <el-form-item label="Übergeordnete Playlist" prop="parent_id">
      <el-select
        v-model="slotProps.model.parent_id"
        v-loading="loadingCategories"
        style="width: 500px;"
        placeholder="Bitte auswählen"
      >
        <el-option label="Keine" value=""></el-option>
        <el-option
          v-for="item in nestedCategories"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item
      v-if="!persisted"
      label="Playlist aus Youtube Playlist erstellen"
      prop="parent_id"
    >
      <el-radio v-model="createFromYoutube" :label="false">Nein</el-radio>
      <el-radio v-model="createFromYoutube" :label="true">Ja</el-radio>
    </el-form-item>

    <div v-if="createFromYoutube">
      <el-form-item
        label="Youtube Playlist ID (Bitte nur Playlist ID, nicht von einem Einzelvideo)"
        prop="youtube_playlist_id"
      >
        <el-input v-model="slotProps.model.youtube_playlist_id"></el-input>
      </el-form-item>
    </div>
    <div v-if="!createFromYoutube || persisted">
      <el-form-item label="Name" prop="name">
        <el-input v-model="slotProps.model.name"></el-input>
      </el-form-item>

      <el-form-item label="Beschreibung" prop="description">
        <el-input
          v-model="slotProps.model.description"
          type="textarea"
          :rows="3"
        ></el-input>
      </el-form-item>

      <image-upload-field
        current-label="Aktuelles Vorschaubild"
        label="Vorschaubild auswählen"
        :current-image="slotProps.model.thumbnail_url"
        allowed-file-types="image/jpeg, image/png"
        @file-chosen="setFile"
      />
    </div>
  </base-form>
</template>

<script>
import BaseForm from "@/components/forms/BaseForm"
import ImageUploadField from "@/components/ImageUploadField"
import VideoCategoriesRepository from "@/repositories/video_categories_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {
    BaseForm,
    ImageUploadField
  },
  mixins: [ApiErrorHandlerMixin],
  props: {
    category: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingCategories: true,
      categories: [],
      createFromYoutube: false
    }
  },
  computed: {
    persisted() {
      return this.category.hasOwnProperty("id")
    },
    nestedCategories() {
      let arr = []
      let that = this
      this.categories.forEach(function(cat) {
        if (cat.videos.length === 0) {
          arr = arr.concat(that.getCategoryChildren(cat))
        }
      })
      return arr
    },
    rules() {
      return {
        name: [
          {
            required: !this.createFromYoutube,
            message: "Bitte Namen angeben",
            trigger: "blur"
          }
        ],
        youtube_playlist_id: [
          {
            required: this.createFromYoutube,
            message: "Bitte ID angeben",
            trigger: "blur"
          },
          { validator: this.validatePlaylistID, trigger: "blur" }
        ]
      }
    }
  },
  async created() {
    try {
      this.categories = await VideoCategoriesRepository.getAll()
      this.loadingCategories = false
    } catch (error) {
      this.loadingCategories = false
      this.handleApiError(error, "Fehler Video Playlists")
    }
  },
  methods: {
    setFile(file) {
      this.category.thumbnail = file
    },
    getCategoryChildren(category, depth = 0) {
      let children = []
      let that = this
      children.push({
        id: category.id,
        name: `${"–".repeat(depth)} ${category.name}`
      })
      category.children.forEach(function(cat) {
        if (cat.videos.length === 0) {
          children = children.concat(that.getCategoryChildren(cat, depth + 1))
        }
      })
      return children
    },
    checkThumbnail() {
      if (
        this.persisted === false &&
        !this.createFromYoutube &&
        !this.category.thumbnail
      ) {
        return false
      }
      return true
    },
    missingThumbnailWarning() {
      this.$message({
        message: `Sie müssen ein Vorschaubild auswählen`,
        type: "error",
        showClose: true
      })
    },
    validatePlaylistID(rule, value, callback) {
      if (value && (value.includes("http://") || value.includes("https://"))) {
        callback(
          new Error(
            "Bitte ausschließlich gültige Youtube Playlist ID (ohne https:// und www) eingeben."
          )
        )
      } else {
        callback()
      }
    }
  }
}
</script>
